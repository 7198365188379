<template>
  <b-sidebar
    id="add-new-connection-sidebar"
    :visible="isAddNewConnectionSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    width="500"
    @change="(val) => $emit('update:is-add-new-connection-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- @hidden="resetForm" -->
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('DataAccess.ConnectionsStrings.AddNew.newConnectionChain') }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
          ref="refNewConnectionString"
        >
          <!--Name field--->
          <validation-provider
            #default="validationContext"
            name="nombre"
            rules="required"
          >
            <b-form-group
              :label="$t('DataAccess.ConnectionsStrings.columns.name')"
            >
              <b-form-input
                v-model="ConnectionStringData.nombre"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--User field--->
          <validation-provider
            #default="validationContext"
            name="usuario"
            rules="required"
          >
            <b-form-group
              :label="$t('DataAccess.ConnectionsStrings.columns.user')"
            >
              <b-form-input
                v-model="ConnectionStringData.usuario"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--Password field--->
          <validation-provider
            #default="validationContext"
            name="New password"
            rules="required"
          >
            <b-form-group
              :label="$t('AppUser.edit.TabChangePassword.newPassword')"
              label-for="new-password"
            >
              <b-input-group>
                <b-form-input
                  id="new-password"
                  v-model="ConnectionStringData.contrasenia"
                  name="new-password"
                  :placeholder="$t('AppUser.edit.TabChangePassword.newPassword')"
                  :state="getValidationState(validationContext)"
                  :type="passwordFieldTypeNew"
                  trim
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconNew"
                    class="cursor-pointer"
                    @click="togglePasswordNew"
                  />
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--servidorInstancia field--->
          <validation-provider
            #default="validationContext"
            name="servidorInstancia"
            rules="required"
          >
            <b-form-group
              :label="$t('DataAccess.ConnectionsStrings.columns.instanceServer')"
            >
              <b-form-input
                v-model="ConnectionStringData.servidorInstancia"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--database name field--->
          <validation-provider
            #default="validationContext"
            name="baseDatosNombre"
            rules="required"
          >
            <b-form-group
              :label="$t('DataAccess.ConnectionsStrings.columns.nameDatabase')"
            >
              <b-form-input
                v-model="ConnectionStringData.baseDatosNombre"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Description field -->
          <validation-provider
            #default="validationContext"
            name="descripcion"
            rules="required"
          >
            <b-form-group
              :label="$t('DataAccess.ConnectionsStrings.columns.description')"
            >
              <b-form-input
                v-model="ConnectionStringData.descripcion"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="system"
          >
            <b-form-group
              :label="$t('DataAccess.ConnectionsStrings.columns.system')"
            >
              <v-select
                v-model="ConnectionStringData.sistemaId"
                label="texto"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="true"
                :options="systems"
                :reduce="option => option.id"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <b-row>
            <b-col
              cols="6"
            >
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                block
                variant="info"
                @click="testingConnectionStringIsValid(ConnectionStringData)"
              >
                Probar cadena de conexion
              </b-button>
            </b-col>
            <b-col cols="6">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                type="submit"
              >
                {{ $t('Lists.Add') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormInvalidFeedback,
  BRow,
  BCol,
} from 'bootstrap-vue'
import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'
import {
  required,
} from '@validations'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import generalDataService from '@/services/generalData.service'
import optionService from '@/services/option.service'
import vSelect from 'vue-select'

export default {
  components: {
    BSidebar,
    ValidationObserver,
    BForm,
    BFormGroup,
    BButton,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormInvalidFeedback,
    ValidationProvider,
    BRow,
    BCol,
    vSelect,
  },
  props: {
    isAddNewConnectionSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    /* Services */
    const {
      createConnectionString,
      testConnectionString,
    } = generalDataService()

    const { fetchSystems } = optionService()
    /* Data */
    const blankConnectionStringData = {
      nombre: null,
      usuario: '',
      contrasenia: '',
      servidorInstancia: '',
      baseDatosNombre: '',
      descripcion: null,
      sistemaId: null,
    }
    const ConnectionStringData = ref(JSON.parse(JSON.stringify(blankConnectionStringData)))
    const resetClassificationData = () => {
      ConnectionStringData.value = JSON.parse(JSON.stringify(blankConnectionStringData))
    }
    const isConnectionStringValid = ref(false)
    const testingConnectionStringIsValid = cadenaConexion => {
      testConnectionString(cadenaConexion)
        .then(data => {
          isConnectionStringValid.value = data.conexionExitosa
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
        })
    }
    const systems = ref([])
    fetchSystems(data => {
      systems.value = data
    })
    const refNewConnectionString = ref(null)
    /* Events */
    const onSubmit = () => {
      createConnectionString(ConnectionStringData.value)
        .then(() => {
          refNewConnectionString.value.reset()
          emit('update:is-add-new-connection-sidebar-active', false)
          emit('refresh-data')
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
        })
    }

    /* Validations */
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetClassificationData)

    return {
      // Data
      systems,
      ConnectionStringData,
      isConnectionStringValid,
      testingConnectionStringIsValid,
      // Events
      onSubmit,
      // Validations
      required,
      getValidationState,
      resetForm,
      refFormObserver,
      refNewConnectionString,
    }
  },
  data() {
    return {
      passwordFieldTypeNew: 'password',
      passwordFieldTypeConfirm: 'password',
    }
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconConfirm() {
      return this.passwordFieldTypeConfirm === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordConfirm() {
      this.passwordFieldTypeConfirm = this.passwordFieldTypeConfirm === 'password' ? 'text' : 'password'
    },
  },
}
</script>

<style>

</style>
